
export default {
    name: 'PreviewProviderAndGameImage',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        path: {
            type: String,
            default: null,
        },
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
