
export default {
    name: 'PlatformIcon',
    props: {
        platformName: {
            type: String,
            default: '',
        },
    },
}
