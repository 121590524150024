
import { mapState } from 'vuex'
export default {
    name: 'PaymentTypeChannels',
    props: {
        value: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        ...mapState({
            paymentTypes: (state) => state.master.paymentTypes,
        }),
        paymentTypeList() {
            return [{ id: null, code: 'all' }, ...this.paymentTypes]
        },
    },
    mounted() {
        this.getPaymentTypesList()
    },
    methods: {
        async getPaymentTypesList() {
            this.loading = true
            await this.$store.dispatch('master/paymentTypes')
            this.loading = false
        },
    },
}
